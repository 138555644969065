import React from 'react';
import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';

import IlustraAnexo from '~/assets/ilustra_anexo.svg';
import { Close, Done } from '@material-ui/icons';
import { darken } from 'polished';
import { uploadDocument } from '~/store/modules/documents/actions';
import Loading from '~/components/Loading';

const schema = Yup.object().shape({
  size: Yup.number().max(10000).required(),
  type: Yup.string().oneOf(
    [
      'application/pdf',
      'image/png',
      'image/jpeg',
      'image/jpg',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    ],
    'Tipo de arquivo inválido'
  ),
});

const useStyles = makeStyles((theme) => ({
  dropZone: {
    border: `4px dashed #BDC0D4`,
    borderRadius: '10px',
    height: '200px',
    margin: '15px 0',
    cursor: 'pointer',
    background: '#F7F8FA',
    padding: '10px',
  },
  internDrop: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  aside: {
    color: '#999',
    textAlign: 'center',
  },
  paside: {
    color: '#50A6FF',
    fontWeight: 'bold',
  },
  pasideu: {
    color: '#333',
  },
  option: {
    display: 'flex',
    justifyContent: 'space-between',
    color: theme.palette.success.main,
  },
  btEnviar: {
    background: '#50A6FF',
    borderRadius: '200px',
    color: '#fff',
    '&:hover': {
      background: darken(0.1, '#50A6FF'),
    },
  },
  divFile: {
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    background: 'rgba(80, 166, 255, 0.4)',
    padding: '11px',
    borderRadius: '8px',
  },
  fileName: {
    maxWidth: '20ch',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: 'rgba(80, 166, 255, 1)',
  },
}));

function Documentos({ contrato_id, pessoa_id }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { deliveredDocuments, missingDocuments, loading, loadingMessage } =
    useSelector((state) => state.documents);
  const unidade = useSelector((state) => state.unidade.data);
  const contratos = useSelector((state) => state.contratos.data);

  const [file, setFile] = React.useState(null);
  const [typeDocument, setTypeDocument] = React.useState(null);

  const onDrop = React.useCallback((acceptedFile) => {
    const { size, type } = acceptedFile[0];
    schema
      .validate({ size: size / 1024, type })
      .then(() => {
        setFile(acceptedFile[0]);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
  });

  if (missingDocuments.length === 0 && deliveredDocuments.length === 0) {
    return (
      <Typography variant="body1" align="center">
        Sem documentos pendentes
      </Typography>
    );
  }

  const handleSend = () => {
    const contratoAtual = contratos.dados.filter(
      (contrato) => contrato.contrato_id === Number(contrato_id)
    );
    dispatch(
      uploadDocument(
        file,
        typeDocument,
        contrato_id,
        contratoAtual[0].numerocontrato,
        pessoa_id,
        unidade.unidade_id
      )
    );
    setFile(null);
    setTypeDocument(null);
  };

  return (
    <Box>
      {loading && <Loading description={loadingMessage} />}
      <Container maxWidth="sm">
        <Grid container>
          <Grid item xs={12}>
            <div className={classes.dropZone}>
              <div className={classes.internDrop} {...getRootProps()}>
                <img src={IlustraAnexo} alt="Ilustração de anexo" />
                <input
                  {...getInputProps()}
                  accept=".pdf, .doc, .docx, .png, .jpg, .jpeg"
                />
                <aside className={classes.aside}>
                  <p className={classes.paside}>
                    Arraste seu arquivo ou{' '}
                    <u className={classes.pasideu}>busque</u>
                  </p>
                  <small>
                    Suportamos um arquivo por vez. Nos formatos .PDF, .DOC, PNG,
                    JPG menores que 10Mb.
                  </small>
                </aside>
              </div>
            </div>
            {file && (
              <div className={classes.divFile}>
                <Typography className={classes.fileName}>
                  {file.name}
                </Typography>
                <Typography>{(file.size / 1000).toFixed(0)}kb</Typography>
              </div>
            )}
            <FormControl
              color="secondary"
              variant="filled"
              fullWidth
              size="small"
              margin="normal"
            >
              <InputLabel id="select-tipo_documento-filled-label">
                Tipo do Documento
              </InputLabel>
              <Select
                color="secondary"
                labelId="select-tipo_documento-filled-label"
                name="tipo_documento"
                onChange={(e) => setTypeDocument(e.target.value)}
                renderValue={(selected) => selected.anexosfaltantes}
              >
                <MenuItem value="" disabled>
                  <em>Escolha o tipo do documento</em>
                </MenuItem>
                {missingDocuments &&
                  missingDocuments.map((document) => (
                    <MenuItem
                      value={document}
                      key={document.intkey}
                      className={classes.option}
                      disabled={document.anexosentregues}
                    >
                      <Typography color="textPrimary">
                        {document.anexosfaltantes}
                      </Typography>
                      {document.anexosentregues ? (
                        <Done color="inherit" />
                      ) : (
                        <Close color="error" />
                      )}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <Button
              variant="contained"
              type="button"
              fullWidth
              className={classes.btEnviar}
              disabled={!file || !typeDocument || loading}
              onClick={handleSend}
            >
              {loading ? 'Carregando' : 'Enviar'}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

Documentos.propTypes = {
  contrato_id: PropTypes.number.isRequired,
  pessoa_id: PropTypes.number.isRequired,
};

export default Documentos;
