import { all, call, put, takeLatest } from 'redux-saga/effects';
import api from '~/services/api';
import apiBackendF10 from '~/services/apiBackendF10';
import { toast } from 'react-toastify';
import {
  fetchDocuments as fetchDocumentsAction,
  fetchDocumentsFailure,
  fetchDocumentsSuccess,
  removeAnexosFaltantes,
  setLoading,
} from './actions';

export function* fetchDocuments({ payload }) {
  try {
    const response = yield call(api.get, `/contrato/documents`, {
      params: payload,
    });

    const deliveredDocuments = response.data.filter(
      (document) => document.anexosentregues
    );

    const missingDocuments = response.data.filter(
      (document) => !document.anexosentregues
    );

    yield put(fetchDocumentsSuccess(deliveredDocuments, missingDocuments));
  } catch (err) {
    yield put(fetchDocumentsFailure());
  }
}

export function* uploadDocument({ payload }) {
  try {
    yield put(setLoading(true, 'Gerando autenticação...'));
    const responseAuth = yield call(
      apiBackendF10.post,
      `/unidade/anexos/contratoLinkUpload`,
      {
        unidade_id: payload.unidade_id,
        contrato_id: payload.contrato_id,
        pessoa_id: payload.pessoa_id,
        description: `${payload.lookupDocument.anexosfaltantes}-${payload.contrato_id}`,
        subtype: 9,
        file_extension: 1,
        size: payload.file.byteLength,
      }
    );

    const { link_id, urlUpload } = responseAuth.data;

    yield put(setLoading(true, 'Salvando arquivo...'));
    yield call(apiBackendF10.put, urlUpload, payload.file, {
      headers: {
        'Content-Type': 'application/pdf',
      },
    });

    const responseConfirm = yield call(
      apiBackendF10.post,
      '/unidade/anexos/confirmLinkUpload',
      {
        link_id,
        unidade_id: payload.unidade_id,
      }
    );

    if (!responseConfirm.data.result) {
      toast.error('Falha ao confirmar upload do arquivo.');
      return;
    }

    yield put(fetchDocumentsAction([payload.numero_contrato]));
    yield put(removeAnexosFaltantes(payload.lookupDocument));
    toast.success('Arquivo enviado com sucesso!');
  } catch (err) {
    console.log(err);
    yield put(fetchDocumentsFailure());
  } finally {
    yield put(setLoading(false, ''));
  }
}

export default all([
  takeLatest('@documents/FETCH', fetchDocuments),
  takeLatest('@documents/UPLOAD', uploadDocument),
]);
