export function fetchDocuments(contratos) {
  return {
    type: '@documents/FETCH',
    payload: { contratos },
  };
}

export function fetchDocumentsSuccess(deliveredDocuments, missingDocuments) {
  return {
    type: '@documents/FETCH_SUCCESS',
    payload: { deliveredDocuments, missingDocuments },
  };
}

export function fetchDocumentsFailure() {
  return {
    type: '@documents/FETCH_FAILURE',
  };
}

export function uploadDocument(
  file,
  lookupDocument,
  contrato_id,
  numero_contrato,
  pessoa_id,
  unidade_id
) {
  return {
    type: '@documents/UPLOAD',
    payload: {
      file,
      lookupDocument,
      contrato_id,
      numero_contrato,
      pessoa_id,
      unidade_id,
    },
  };
}

export function setLoading(loading, message) {
  return {
    type: '@documents/SET_LOADING',
    payload: { loading, message },
  };
}

export function removeAnexosFaltantes(typeDocument) {
  return {
    type: '@documents/REMOVE_ANEXOS_FALTANTES',
    payload: { typeDocument },
  };
}
